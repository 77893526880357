<script>
import crudMixin from '@/mixins/crud';
import APIZone from '@/services/API/Zone';

export default {
  mixins: [
    crudMixin('zone', () => ({
      name: '',
      radius: 0,
    }), APIZone),
  ],
};
</script>

<template>
  <section class="section">
    <header class="is-flex is-justify-content-space-between">
      <h1 class="title">
        Zones de livraisons
      </h1>
      <b-button class="flex-none" type="is-primary" @click="addZone">
        Ajouter
      </b-button>
    </header>
    <table class="table is-bordered is-fullwidth">
      <tr>
        <th>Lieu</th>
      </tr>
      <tr v-for="item in zone.list" :key="item.id">
        <td class="is-flex is-justify-content-space-between">
          {{ item.name }}
          <b-button
            class="has-text-danger"
            type="is-text"
            size="is-small"
            @click="deleteZone(item)">
            Supprimer
          </b-button>
        </td>
      </tr>
    </table>
    <b-modal width="520px" :active.sync="zone.isFormOpen">
      <form class="box" @submit.prevent="isDataValid() && handleZone()">
        <h2 class="title is-4">
          Ajouter
        </h2>
        <b-field label="Nom" label-for="name">
          <b-input v-model="zone.form.name" id="name" />
        </b-field>
        <b-field>
          <b-button type="is-primary" native-type="submit" expanded>
            Envoyer
          </b-button>
        </b-field>
      </form>
    </b-modal>
  </section>
</template>
